import React from "react"
import { Link } from "gatsby"

import Layout from "gatsby-theme-stack/src/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <section className="section-padding">
      <h1>Hi from about page</h1>
      <p>Welcome to about page</p>
      <Link to="/">Go back to the homepage</Link>
    </section>
  </Layout>
)

export default AboutPage
